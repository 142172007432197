/* Подключение минифицированных стилей из node modules */
@import "~materialize-css/dist/css/materialize.min.css";

.mr-1 {
    margin-right: 1rem;
}

.input-hover-yellow{
    color: #fff;
}

.input-hover-yellow:focus{
    border-bottom: 1px solid yellow !important;
    box-shadow: 0 1px 0 0 yellow !important;
}

.input-hover-yellow:focus + label{
   color: yellow !important;
}

.loader-wr{
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.7);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}